import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import TabsSection from "@components/orangetabs-section/layout-2";
import IntroArea from "@components/introarea/layout-2";
import TrManagementKeyFeatures from "@containers/translation-management/tr-management-keyfeatures";
import BoxSection from "@components/BoxSection/layout-two/layout-1";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";

const TranslationManagementPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Translation Management" 
       />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader
          data={content["translation-management-header-section"]}
          pageContext={content["translation-management-meta-description"]}
        />
        <FeatureGrid data={content["translation-management-features-body"]} />
        <IntroArea
          layout={2}
          data={content["translation-management-intro"]}
        />
        <TabsSection data={content["translation-management-tool-body"]} />
        <TrManagementKeyFeatures
          data={content["key-fetaures-translation-body"]}
        />
        <BoxSection
          layout={3}
          data={content["benifits-translation-management-body"]}
        />
        <CtaArea data={content["cta-data"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query translationManagementPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "translation-management" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

TranslationManagementPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default TranslationManagementPage;
